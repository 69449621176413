.quotePage {


 padding:10px;

  .manage-button {
    color:white;
    font-family: 'Gilroy';
    font-weight: 800;
    font-size: 14px;
    border: none;
    padding: 8px;
    height: 42px;
    border-radius: 4px;

    box-shadow: 0 5px 15px 0 rgb(0 0 0 / 15%);
    background-color: #fecb0a;

  }
  .manage-button:hover{
    opacity: 0.7;
  }
}



.header {
  text-align: center;
  font-weight: 500;
  font-size: 26px;
  padding: 10px;

}

.disclaimer {
  margin-top: 10px;
}

.disclaimer-review {
  margin-top: 35px;
}


.car-info {
  font-weight: 400;
  font-size: 20px;
}

.car-info-title {
  margin-top: 5px;
  margin-bottom: 5px;
  span {
    color: #9b9b9b;
    font-size: 16px;
  }
}

.questions-wrapper {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 30px;


}

.additional {
  margin-top: 8px;
}

.job-info {
  font-weight: 400;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  li {
    font-size: 16px;
    padding:10px !important;
  }
}

.parts-labour-title {
  font-weight: 400;
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 5px;

  span {
    color: red;
    font-size: 16px;
  }
}

.action-wrapper {
  display: flex;
  flex-direction: column;

  button {
    width: 100%;
    background-color: #6c757d;
    border-color:  #6c757d;
    font-weight: 500;
    border: 1px solid;
    text-align: center;
    color: white;
    border-radius: 4px;
    padding: 12px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    margin-left: 10px;

  }

  textarea {
    padding:20px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border: none;
  }



}


.warning-text {
  margin-top:15px;

}


.buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 25px;



  .submit-button {
    color: black;
    font-weight: 400;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border-radius: 4px;
    text-align: center;
    border: none;
    padding: 12px;
    background-color: #fecb0a;
    margin-right: 10px;

  }
  .decline-button {
    color: white;
    font-weight: 400;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border-radius: 4px;
    text-align: center;
    border: none;
    padding: 12px;
    background-color: #dc3545;
  }


}

.input-group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap !important;



}

.input-before {
  text-align: center;
  justify-content: center;
  color:#495057;
  background-color: #e9ecef;
  padding: 8px;
  border-radius: 4px;
}


.input {
  display: block;
  font-weight: 400;
  color: #495057;
  font-weight: 400;
  line-height: 1.5px;
  background-color:#ffffff;
  border-radius: 4px;
  border: none;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  width: 90%;
  margin-right: 20px;
}

select {
  display: block;
  font-weight: 400;
  color: #495057;
  font-weight: 400;
  line-height: 1.5px;
  background-color:#ffffff;
  border-radius: 4px;
  border: none;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  width: 90%;
  margin-right: 20px;
}

.cell-header {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.cell-item-text {
  font-size: 16px !important;
}

.cell-item-text-bold {
  font-size: 16px !important;
  font-weight: 700 !important;
  text-align: right !important;
}



.cell-item-delete-cross {

  button {
    border-radius: 4px;
    background-color: #dc3545;
    border: none;
    width: 25px;
    height: 25px;
    color: white;
    font-weight: 700;

  }
}


.notes-wrapper {

  padding: 0px !important;
}
